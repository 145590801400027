import React, { Component } from 'react';

import 'react-dates/initialize'; // necessary for latest version
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null,
        };
    }

    render() {
        return (
            <DateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                startDatePlaceholderText="Arrival"
                endDatePlaceholderText="Departure"
                onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate })}}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => { this.setState({ focusedInput })}}
            />
        );
    }
}

export default App
