import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import './global.css';

ReactDOM.render(
   <App />,
  document.getElementById('date-picker-container')
);

